import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {css} from '@emotion/react';
import {mobile, tablet} from '~styles/global';
import {graphql} from 'gatsby';
import DataViewer from '~utils/DataViewer';
import ScrollSelector from '~components/ScrollSelector';
import {useSiteState} from '~context/siteContext';
import useBreakpoint from '~utils/useBreakpoint';
import Seo from '~components/Seo';
import ProjectGridMobile from '~components/ProjectGridMobile';

const Production = ({data}) => {
	console.log(data);
	const page = data.page;
	const [siteState, setSiteState] = useSiteState();
	const {isMobile, loaded} = useBreakpoint();

	useEffect(() => {
		setSiteState((prevState) => ({
			...prevState,
			pageTitle: page.title,
		}));
	}, []);

	return (
		<>
			<Seo title={page.title} metaTitle={page.seo?.metaTitle} description={page.seo?.metaDescription} image={page.seo?.socialImage} />
			{loaded && (
				<Wrap>
					{isMobile ? (
						<>
							{console.log('mobile loaded')}
							<ProjectGridMobile data={data.page} bgColor="black" />
						</>
					) : (
						<>
							{console.log('desktop loaded')}
							<ScrollSelector data={data.page} bgColor="black" />
						</>
					)}
					<DataViewer data={page} name="page" />
				</Wrap>
			)}
		</>
	);
};

const Wrap = styled.div`
	background-color: var(--black);
	/* height: 100vh; */
`;

Production.propTypes = {
	data: PropTypes.object,
};

export const query = graphql`
	query ProductionQuery($slug: String) {
		page: sanityProduction(slug: {current: {eq: $slug}}) {
			title
			slug {
				current
			}
			seo {
				...seo
			}
			projects {
				thumbnail {
					...thumbnail
				}
				title
				_type
				client
				projectName
				slug {
					current
				}
			}
		}
	}
`;

export default Production;
